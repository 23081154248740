import Vue from 'vue';
import VueRouter from 'vue-router';
import OrganizationService from '@/pages/organizations/OrganizationService';

Vue.use(VueRouter);

const UUID_REGEX = '[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

// Please refer to this inside your `$router.push({name: routeNames.XXXX}).catch(() => {});`
export const routeNames = {
  Home: 'Home',
  DeploymentCreationSuccess: 'DeploymentCreationSuccess',
  DeploymentList: 'DeploymentList',
  DeploymentCreate: 'DeploymentCreate',
  DeploymentDetails: 'DeploymentDetails',
  DeploymentLogs: 'DeploymentLogs',
  DeploymentExports: 'DeploymentExports',
  DeploymentConfigurations: 'DeploymentConfigurations',
  DeploymentInstances: 'DeploymentInstances',
  DeploymentInsights: 'DeploymentInsights',
  DeploymentAudits: 'DeploymentAudits',
  DeploymentAdvanced: 'DeploymentAdvanced',
  OrganizationDetails: 'OrganizationDetails',
  OrganizationEdit: 'OrganizationEdit',
  InviteMember: 'InviteMember',
  CreateServiceAccount: 'CreateServiceAccount',
  Documentation: 'Documentation',
  DocumentationAPI: 'DocumentationAPI',
  SettingsUser: 'SettingsUser',
  TicketList: 'TicketList',
  ReportIncident: 'ReportIncident',
  Error404: 'Error404',
  Backoffice: 'Backoffice',
  BackofficeDeployments: 'BackofficeDeployments',
  BackofficeMinorUpgrade: 'BackofficeMinorUpgrade'
};

const routes = [
  {
    path: '/',
    name: routeNames.Home,
    redirect: { name: routeNames.DeploymentList },
  },
  {
    path: '/organizations',
    name: routeNames.Home,
    redirect: { name: routeNames.OrganizationDetails },
  },
  {
    path: `/organizations/:id(${UUID_REGEX})`,
    name: routeNames.OrganizationDetails,
    component: () => import(/* webpackChunkName: "OrganizationList" */ './pages/organizations/Organization'),
  },
  {
    path: `/organizations/:id(${UUID_REGEX})/edit`,
    name: routeNames.OrganizationEdit,
    component: () => import(/* webpackChunkName: "OrganizationEdit" */ './pages/organizations/OrganizationEdit'),
  },
  {
    path: `/organizations/:id(${UUID_REGEX})/members/invite`,
    name: routeNames.InviteMember,
    component: () => import(/* webpackChunkName: "InviteMember" */ './pages/organizations/InviteMember'),
  },
  {
    path: `/organizations/:id(${UUID_REGEX})/members/service-account`,
    name: routeNames.CreateServiceAccount,
    component: () => import(/* webpackChunkName: "CreateServiceAccount" */ './pages/organizations/CreateServiceAccount'),
  },
  {
    path: `/deployments`,
    name: routeNames.DeploymentList,
    component: () => import(/* webpackChunkName: "DeploymentList" */ './pages/deployments/DeploymentList'),
  },
  {
    path: `/deployments/create`,
    name: routeNames.DeploymentCreate,
    component: () => import(/* webpackChunkName: "DeploymentCreate" */ './pages/deployments/DeploymentCreate'),
  },
  {
    path: `/deployments/creation-success`,
    name: routeNames.DeploymentCreationSuccess,
    component: () =>
      import(/* webpackChunkName: "DeploymentCreationSuccess" */ './pages/deployments/DeploymentCreationSuccess'),
  },
  {
    path: `/deployments/:id(${UUID_REGEX})`,
    component: () => import(/* webpackChunkName: "Deployment" */ './pages/deployments/Deployment'),
    children: [
      {
        path: ``,
        name: routeNames.DeploymentDetails,
        component: () =>
          import(/* webpackChunkName: "DeploymentDetails" */ './pages/deployments/details/DeploymentDetails'),
      },
      {
        path: `logs`,
        name: routeNames.DeploymentLogs,
        component: () => import(/* webpackChunkName: "DeploymentLogs" */ './pages/deployments/logs/DeploymentLogs'),
      },
      {
        path: `exports`,
        name: routeNames.DeploymentExports,
        component: () =>
          import(/* webpackChunkName: "DeploymentExports" */ './pages/deployments/export/DeploymentExportList'),
      },
      {
        path: `configurations`,
        name: routeNames.DeploymentConfigurations,
        component: () =>
          import(
            /* webpackChunkName: "DeploymentConfigurations" */ './pages/deployments/configurations/DeploymentConfigurations'
          ),
      },
      {
        path: `audits`,
        name: routeNames.DeploymentAudits,
        component: () =>
          import(/* webpackChunkName: "DeploymentConfigurations" */ './pages/deployments/audits/DeploymentAudits'),
      },
      {
        path: `advanced`,
        name: routeNames.DeploymentAdvanced,
        component: () =>
          import(/* webpackChunkName: "DeploymentConfigurations" */ './pages/deployments/advanced/DeploymentAdvanced'),
      },
      {
        path: `instances`,
        name: routeNames.DeploymentInstances,
        component: () =>
          import(/* webpackChunkName: "DeploymentConfigurations" */ './pages/deployments/instances/DeploymentInstances'),
      },
      {
        path: `insights`,
        name: routeNames.DeploymentInsights,
        component: () =>
          import(/* webpackChunkName: "DeploymentConfigurations" */ './pages/deployments/insights/DeploymentInsights'),
      },
    ],
  },
  {
    path: `/settings/user`,
    name: routeNames.SettingsUser,
    component: () => import(/* webpackChunkName: "User" */ './pages/settings/User'),
  },
  {
    path: `/support/tickets`,
    name: routeNames.TicketList,
    component: () => import(/* webpackChunkName: "TicketList" */ './pages/support/TicketList'),
  },
  {
    path: `/support/incident`,
    name: routeNames.ReportIncident,
    component: () => import(/* webpackChunkName: "ReportIncident" */ './pages/support/ReportIncident'),
  },

  {
    path: `/documentation`,
    name: routeNames.Documentation,
    component: () => import(/* webpackChunkName: "Documentation" */ './pages/documentation/Documentation'),
    children: [
      {
        path: `/api`,
        name: routeNames.DocumentationAPI,
        component: () => import(/* webpackChunkName: "DocumentationAPI" */ './pages/documentation/api/Api'),
      },
    ],
  },
  {
    path: '/backoffice',
    name: routeNames.Backoffice,
    component: () => import('./pages/backoffice/Backoffice.vue'),
    meta: {
      requiresAdminRole: true
    },
    redirect: { name: routeNames.BackofficeDeployments },
    children: [
      {
        path: 'deployments',
        name: routeNames.BackofficeDeployments,
        component: () => import('./pages/backoffice/BackofficeDeployments.vue')
      },
      {
        path: 'minor-upgrade',
        name: routeNames.BackofficeMinorUpgrade,
        component: () => import('./pages/backoffice/MinorUpgrade.vue')
      }
    ]
  },
  {
    path: '*',
    name: routeNames.Error404,
    component: () => import(/* webpackChunkName: "NotFound" */ './pages/NotFoundPage'),
    // beforeEnter: (to, from, next) => {
    //   console.log(from +' -> '+ to +' : '+ next)
    // }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/**
 * Custom made for Cloud-IAM, update a part of the current query string
 * @param {string | object} queryparam - either a single query param (as string) to change or a key-value object
 * @param {mixed | undefined} value
 * @example ciamUpdateQuery('organization_id', uuid)
 * @example ciamUpdateQuery({organization_id: uuid, page: 0, sort: 'asc'})
 * @return {Promise<Route>}
 */
router.ciamUpdateQuery = (queryparam, value) => {
  return router
    .push({
      ...router.currentRoute,
      query: {
        ...router.currentRoute.query,
        ...(typeof queryparam === 'string' && value !== undefined
          ? { [queryparam]: value }
          : Object.prototype.toString.call(queryparam) === '[object Object]' && value === undefined
          ? queryparam
          : (function () {
              throw new Error('Invalid ciamUpdateQuery parameters, check docs');
            })()),
      },
    })
    .catch(() => {});
};

router.beforeEach(async (to, from, next) => {
  function addOrganizationId(base, organization_id) {
    next({
      ...base,
      query: {
        ...base.query,
        organization_id: organization_id,
      },
    });
  }

  const from_has_organization_id = String(from.query.organization_id).includes('-');
  const to_has_organization_id = String(to.query.organization_id).includes('-');

  if (to_has_organization_id) {
    //  move on to the next hook in the pipeline
    return next();
  }

  if (from_has_organization_id && !to_has_organization_id) {
    addOrganizationId(to, from.query.organization_id);
    return;
  }

  await OrganizationService.getAll()
    .then((organizations) => {
      if (!Array.isArray(organizations) || organizations.length === 0) {
        return next({ name: routeNames.Error404 });
      }

      addOrganizationId(to, organizations[0].id);
    })
    .catch(() => {});
});

export default router;
