import { problemAwareHttp } from '@/http';

export default class UserService {
  static async login(user) {
    return problemAwareHttp.put(`/users/${user.id}`, user).then((response) => response.data);
  }
  static async createDevice(userId) {
    return problemAwareHttp.post(`/users/${userId}/mfa/devices/otp`).then((response) => response.data);
  }
  static async getMFADevices(userId) {
    return problemAwareHttp.get(`/users/${userId}/mfa/devices/otp`).then((response) => response.data);
  }
  static async removeMFADevice(userId, deviceId) {
    return problemAwareHttp.delete(`/users/${userId}/mfa/devices/otp/${deviceId}`).then((response) => response.data);
  }
  static async deleteUser(userId) {
    return problemAwareHttp.delete(`/users/${userId}`).then((response) => response.data)
  }
  static async toggleSubscriptionToNl(userId, newValue) {
    return problemAwareHttp.put(`/users/${userId}/newsletters`, { subscribeNl: newValue }).then((response) => response.data);
  }
  static async getUser(userId) { 
    return problemAwareHttp.get(`/users/${userId}`).then((response) => response.data); 
  }
}
