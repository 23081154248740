<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <div>
        <p class="modal-card-title">
          <slot name="header" />
        </p>
        <p class="modal-card-subtitle" v-if="$slots.subtitle">
          <slot name="subtitle" />
        </p>
      </div>
    </header>
    <section class="modal-card-body">
      <slot :item="item" />
    </section>
    <footer class="modal-card-foot buttons is-right">
      <button
        class="button is-light"
        v-for="(btn, i) of buttons"
        :key="i"
        @click="handleClick(btn)"
        :class="btn.classes"
        :disabled="buttonsDelay[i] > 0"
      >
        {{ btn.text }}
        <strong class="counter" v-if="buttonsDelay[i] > 0" v-text="buttonsDelay[i]" />
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'StandardModal',
  data() {
    return {
      tickInterval$: null,
      buttonsDelay: [],
    };
  },
  props: {
    item: Object,
    buttons: Array,
  },
  beforeMount() {
    for (const i in this.buttons) {
      const b = this.buttons[i];
      if (b.delayed) this.buttonsDelay[i] = b.delayed;
    }
  },
  mounted() {
    this.tickInterval$ = setInterval(() => this.tick(), 1000);
  },
  destroyed() {
    if (this.tickInterval$) clearTimeout(this.tickInterval$);
  },
  methods: {
    tick() {
      this.buttonsDelay = this.buttonsDelay.map((d) => d - 1);
      const remaining = this.buttonsDelay.filter((d) => d > 0);

      if (remaining.length == 0) clearTimeout(this.tickInterval$);
    },
    handleClick(btn) {
      if (btn.returnVal) this.$emit('result', { result: this.item });

      if (!btn.preventClose) this.$parent.close();
    },
  },
};
</script>

<style lang="scss" scoped>
strong.counter {
  margin-left: 5px;
}

.modal-card-subtitle {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
}
</style>
